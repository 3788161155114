import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import NotFoundLayout from '../components/organisms/NotFoundLayout'

const DESCRIPTION = 'Achetez l’appartement ou la maison de vos rêves parmi plus de 1000 sites immobiliers. Lancez votre recherche et recevez les annonces qui vous correspondent.'

const NotFoundPage = ({ location }) => (
  <>
    <SEO
      title="404: Not found"
      location={location}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <NotFoundLayout />
    <Footer
      noForm={true}
      noImage={true} />
  </>
)

NotFoundPage.propTypes = {
  location: PropTypes.object
}

export default NotFoundPage
