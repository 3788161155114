import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import LaunchButton from '../../atoms/LaunchButton'
import NotFoundImage from '../../atoms/NotFoundImage'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const NotFoundContainer = styled.div`
  width: 100%;
  height: 73vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => get(theme, 'paleGrey', '#fff')};
`

const Card = styled.div`
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 73, 238, 0.15);
  background-color: ${({ theme }) => get(theme, 'white', '#fff')};
  padding: 43px 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  margin-left: 10px;
`

const Text = styled(HomeSectionDescription)`
  margin-top: 34px;
  margin-bottom: 34px;
`

const NotFoundLayout = () => (
  <NotFoundContainer>
    <Card>
      <TitleContainer>
        <NotFoundImage />
        <Title>
          Erreur 404.
        </Title>
      </TitleContainer>
      <Text>
        L’URL demandée est introuvable sur ce site.
      </Text>
      <LaunchButton
        link='/'
        title="Retourner à l'accueil"
        background='blue' />
    </Card>
  </NotFoundContainer>
)

export default NotFoundLayout
